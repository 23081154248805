import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../Components/Footer';
import LightLogo from '../Components/LightLogo';
import CaptionedSlideshow from '../Components/CaptionedSlideshow';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

interface FoundPhoto {
  url: string;
  date: string;
}
interface OtherQuery {
  id: string;
  creatorId: number;
  photos: Array<FoundPhoto>;
}
interface UnlockedQueryResult {
  subject_id: string;
  my_photos: Array<FoundPhoto>;
  queries: Array<OtherQuery>;
}
interface ResultsProps {
  authToken: string;
  setUploadedImages: React.Dispatch<React.SetStateAction<string[]>>;
}

const Results:React.FC<ResultsProps> = ({ authToken, setUploadedImages }) => {
  const navigate = useNavigate();
  const [queryResult, setQueryResult] = useState<UnlockedQueryResult | null>(null);
  const { id } = useParams();

  const createChat = (query: OtherQuery) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "other_user_id": query.creatorId,
      "avatar": query.photos.length > 0 ? query.photos[0].url : null
    });

    var requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://api.ishemine.com/chat/get/", requestOptions)
      .then<[boolean, any]>(response => response.json().then(data => [response.ok, data]))
      .then((value: [boolean, any]) => {
          const [ok, result] = value;
          if (ok) {
            navigate(`/chat/${result.id}`);
          }
      })
      .catch(error => alert(error));

  }

  const loadResults = (failureCount = 0) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authToken);

    var requestOptions: RequestInit = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    fetch(`https://api.ishemine.com/evals/query/results/?query_id=${id}`, requestOptions)
    .then(response => {
      if (!response.ok) {
        if (response.status === 412) {
          response.json().then(json => {
            setUploadedImages(json.my_photos.map((photo: any) => photo.url));
            navigate(`/hes-mine/${id}`, { replace: true });
          });
        } else if (failureCount > 1) {
          navigate(`/checkout/${id}`, { replace: true }); // need to redirect to payment if truly failed
        } else {
          setTimeout(() => loadResults(failureCount + 1), 1000); // Possibly stripe webhook not yet processed
        }
      } else {
        response.json().then(json => {
          setQueryResult(json);
        });
      }
    }).catch(error => {
      console.log('Error:', error);
    })
  }
  
  useEffect(() => {
    if (id === "") navigate("/");
    loadResults();
  }, [id]);

  useEffect(() => {
    if (queryResult && queryResult.queries.length === 0) {
      setUploadedImages(queryResult.my_photos.map((photo: any) => photo.url));
      navigate(`/hes-mine/${id}`, { replace: true });
    }
  }, [queryResult]);
  
  return (
      <div className="flex flex-col bg-black">
        <div className="min-h-screen">
          <header className="p-4">
            <div className="container mx-auto flex items-center justify-between hover:cursor-pointer" onMouseUp={() => navigate("/")}>
              <LightLogo/>
            </div>
          </header>
          {/* <div className="h-px bg-gray-500"></div> */}
            <main className="flex-1 p-4 text-center text-white">
              <h1 className="dela-gothic text-2xl">Hey girly...<br/>We found a match. 🙊</h1>
              <h3 className="text-sm">We're here for you.</h3>
              <div className="flex justify-center">
                {queryResult ? 
                  <div className="text-left mt-4 w-full md:w-1/2 lg:w-1/3">
                      <h2 className="font-bold">Searches on IsHeMine.com ({queryResult.queries.length})</h2>
                      {queryResult.queries.map((query: OtherQuery, idx: number) => {
                        return (
                          <div key={idx} className="border-b border-gray-300"> {/* Added Border Here */}
                            <CaptionedSlideshow onChatClick={() => createChat(query)} full={true} images={query.photos.map(photo => photo.url)} captions={query.photos.map((photo) => `${dayjs(photo.date).fromNow()}`)} authToken={authToken} />
                          </div>
                        );
                      })}
                  </div> : <div className="h-px bg-gray-500">Loading...</div> // TODO: loading animation!
                }
              </div>
            </main>
        </div>
        <Footer/>
      </div>
  );
}

export default Results;
