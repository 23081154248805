import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useNavigate } from 'react-router-dom';
import Footer from '../Components/Footer';
import LightLogo from '../Components/LightLogo';
import Post from '../Components/Post';
import { PostTypes } from './types';

dayjs.extend(relativeTime);

interface FakeResultsProps {
    otherGirlPhotos: string[];
}

const FakeResults:React.FC<FakeResultsProps> = ({ otherGirlPhotos }) => {
  const navigate = useNavigate();
  const [showDisclaimer, setShowDisclaimer] = useState(true); 
  const handleDisclaimerClose = () => {
    document.body.style.overflow = 'auto'; // Re-enable scrolling when the disclaimer is closed
    setShowDisclaimer(false);
  }

  useEffect(() => {
    if (showDisclaimer) {
      document.body.style.overflow = 'hidden';
    }
    // Re-enable scrolling when the component is unmounted
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showDisclaimer]);
  
  return (
      <div className="flex flex-col bg-gray-900 relative">
        {/* Disclaimer modal */}
        {showDisclaimer && (
          <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50 p-8">
            <div className="bg-red-100 p-4 rounded-lg text-center border border-blue-200 w-full md:w-1/2 lg:w-1/3">
              <h2 className="font-bold text-xl mb-2 dela-gothic">Disclaimer</h2>
              <p className="mb-4 text-left">Our facial detection algorithm operates with ~95% confidence. This means, if your man is in our database, he <b>will</b> show up in this search.</p>
              <p className="mb-8 text-left"> However, other men may also be included, so <b>please click <span className="underline text-red-700">Not Him?</span></b> wherever you notice someone who is <b>not your boyfriend.</b></p>
              <button className="bg-red-600 text-white px-4 py-2 rounded text-sm font-bold transition duration-300 ease-in-out transform hover:scale-105" onClick={handleDisclaimerClose}>I understand!</button>
            </div>
          </div>
        )}
        <div className="min-h-screen">
          <header className="p-4">
            <div className="container mx-auto flex items-center justify-between hover:cursor-pointer" onMouseUp={() => navigate("/")}>
              <LightLogo/>
            </div>
          </header>
          {/* <div className="h-px bg-gray-500"></div> */}
          <main className="flex-1 p-4 text-center text-white">
            <h1 className="dela-gothic text-xl mb-1">Hey girly...<br/>We found a match. 🙊</h1>
            <h3 className="text-sm">We're here for you.</h3>
            <div className="flex justify-center">
              {otherGirlPhotos ? 
                <div className="text-left mt-4 w-full md:w-1/2 lg:w-1/3">
                    {/* <h2 className="font-bold">Searches on IsHeMine.com ({otherGirlPhotos.length})</h2> */}
                    {otherGirlPhotos.map((url: string, idx: number) => {
                        if (url) {
                        // Generate a random number of days between 1 and 365
                        const randomDays = Math.floor(Math.random() * 365) + 1;
                        // Subtract the random number of days from the current date
                        const fakeDate = dayjs().subtract(randomDays, 'day').format('YYYY-MM-DD HH:mm:ss');
                        return (
                            <div key={idx} className="border-b border-gray-300">
                                <Post
                                    postType={PostTypes.SEARCH}
                                    onChatClick={() => { navigate('/ugc/chat') }}
                                    full={true}
                                    images={[url]}
                                    initialRating={82}
                                    elapsedTime={dayjs(fakeDate).fromNow()}
                                    caption={"Spotted: Our state-of-the-art love radar just caught a wild boyfriend in his natural habitat - the gym! Reportedly, he was flexing more than just his muscles. Stay tuned for more juicy updates in this romantic saga."}
                                    authToken={''}
                                />
                            </div>
                        );
                        }
                    })}
                </div> : <div className="h-px bg-gray-500">Loading...</div> // TODO: loading animation!
              }
            </div>
          </main>
        </div>
        <Footer/>
      </div>
  );
}

export default FakeResults;
